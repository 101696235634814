
























import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarParityPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-parity-page-otel-logs.mixin';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import BmlTable from '../components/bml-table/bml-table.vue';
import BmlTableInfo from '../components/bml-table/bml-table-info.vue';
import ParityActions from '../components/parity-actions.vue';
import BmlOverTime from '../components/bml-over-time/bml-over-time.vue';
import BmlSpread from '../components/bml-spread/bml-spread.vue';
import ParitySettingsService, { ParitySettingsServiceS } from '../parity-settings.service';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        ParityActions,
        BmlTable,
        BmlTableInfo,
        BmlOverTime,
        BmlSpread,
    },
})
export default class ParityPage extends Mixins(CarParityPageOtelLogs) {
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(UserServiceS) userService!: UserService;

    mounted() {
        this.paritySettingsService.provider = this.$route.params.provider;
        // if (!this.userService.currentCompany) {
        //     return;
        // }
        // if (this.userService.currentCompany.toLowerCase() === 'rentalcars') {
        //     this.$router.push('/');
        // }
    }

    get brokerName() {
        return this.paritySettingsService.provider;
    }

    showAllBrokers(e: Event) {
        e.preventDefault();
        this.paritySettingsService.storeState.settings.provider = '';
        this.$router.push({ name: 'parity' });
    }
}
