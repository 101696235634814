






















import { Prop, Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ParityCarClassesFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-car-classes-filter.vue';
import ParityCountryFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-country-filter.vue';
import ParityLokFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-lok-filter.vue';
import ParityMileageFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-mileage-filter.vue';
import ParityPaymentTermsFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-payment-terms-filter.vue';
import ParityPickUpCityFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-pick-up-city-filter.vue';
import ParityDataSourcesFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-data-sources-filter.vue';
import ParityPosFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-pos-filter.vue';
import ParityTransmissionFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-transmission-filter.vue';
import PARITY_FILTER from '@/modules/cars/modules/parity/constants/parity-filter.constant';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import FilterOptions from '@/modules/cars/components/cars-filters/filter-options.vue';
import ParityBmlFilter from './parity-filters/bml-filter.vue';

@Component({
    components: {
        ParityPickUpCityFilter,
        ParityLokFilter,
        ParityPosFilter,
        ParityTransmissionFilter,
        ParityMileageFilter,
        ParityPaymentTermsFilter,
        ParityCountryFilter,
        ParityCarClassesFilter,
        ParityBmlFilter,
        ParityDataSourcesFilter,
        FilterOptions,
    },
})

export default class ParityFilterSet extends Vue {
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;

    @Prop({
        type: Array,
        required: true,
    })
    activeFilters!: PARITY_FILTER[];

    isCountry(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.COUNTRY;
    }

    isPickup(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.PICKUP;
    }

    isDataSourcesFilter(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.DATA_SOURCES && this.carsSharedService.isBrokerMode;
    }

    isLok(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.LOK;
    }

    isPos(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.POS;
    }

    isCarCategory(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.CAR_CATEGORY;
    }

    isTransmission(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.TRANSMISSION;
    }

    isMileage(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.MILEAGE;
    }

    isPaymentTerms(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.PAYMENT_TERMS;
    }

    isBml(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.BML;
    }

    onFiltersSave() {
        this.parityFiltersService.saveUserFilterValues();
    }

    onFiltersClear() {
        this.parityFiltersService.clearUserFilterValues();
    }
}
